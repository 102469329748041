<template>
    <modal name="member-info-modal" class="member-info-modal"
           :width="285"
           :height="365"
           :adaptive="true"
           :transition="`modal-bottom`"
           @before-open="scrollDisable"
           @before-close="scrollAble"
    >
<button class="modal_close" @click="backEvent"></button>
        <member-detail-component :memberType="'modalMemberInfo'" :memberKey="memberKey"></member-detail-component>

    </modal>
</template>

<script>
    import EventBus from "@/utils/event-bus";
    import MemberDetailComponent from "@/components/member/MemberDetailComponent";
    export default {
        name: "MemberInfoModal",
        mixins: [],
        components: {
            MemberDetailComponent
        },
        props: {
            memberKey: {

            },
        },
        data() {
            return {
            }
        },
        beforeRouterEnter() {},
        created() {
            EventBus.$on('backEvent_info',this.backEvent)
        },
        mounted() {
            EventBus.$off('backEvent_info')
        },
        beforeDestroy() {
        },
        destroyed() {},
        computed: {
        },
        methods:{
            backEvent() {
                this.$modal.hide('member-info-modal')

            },
            scrollDisable(){
                $('html, body').addClass('hidden');
            },
            scrollAble(){
                $('html, body').removeClass('hidden');

            }

        },
        watch: {

        },
    }
</script>

<style lang="scss">

</style>