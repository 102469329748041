<template>
<modal name="terms-modal" class="terms-modal vh100"
           :width="'100%'"
           :height="'100%'"
           :pivotY="0"
           :transition="`modal-bottom`"
       @before-open="scrollDisable"
       @before-close="scrollAble"
    >
        <div>
            <page-header :BackEvent="backEvent" :headerTitle="'서비스이용약관'"></page-header>
            <ion-content>
                <terms-component class="service_term" :termsType="termsType"></terms-component>
            </ion-content>
        </div>
    </modal>
</template>

<script>
    import EventBus from "@/utils/event-bus";
    import TermsComponent from "@/components/terms/TermsComponent";
    export default {
        name: "TermsModal",
        mixins: [],
        components: {
            TermsComponent
        },
        props: {
            termsType: {
                default: '',
            },
        },
        data() {
            return {
            }
        },
        beforeRouterEnter() {},
        created() {
            //EventBus.$on
        },
        mounted() {
            //EventBus.$on('')
        },
        beforeDestroy() {
        },
        destroyed() {},
        computed: {
        },
        methods:{
            backEvent() {
                this.$modal.hide('terms-modal')
            },
            scrollDisable(){
                $('html, body').addClass('hidden');
            },
            scrollAble(){
                $('html, body').removeClass('hidden');

            }

        },
        watch: {

        },
    }
</script>

<style lang="scss">

</style>