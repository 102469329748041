<template>
    <div class="mypage">
        <member-update-link :updateLayout="updateLayout"></member-update-link>
    </div>
</template>

<script>
    import memberUtil from "@/components/member/memberUtil"
    import EventBus from "@/utils/event-bus";
    import {mapGetters, mapState} from "vuex";
    import MemberUpdateLink from "./MemberUpdateLink";
    import MemberValidator from "@/mixins/validators/MemberValidator";
    import VuxAlert from "@/mixins/vux-alert";
    import util from "@/mixins/util";
    import elFileMixins from "@/mixins/elFileMixins";

    export default {
        name: "MemberUpdateComponent",
        mixins: [memberUtil,MemberValidator, VuxAlert,elFileMixins],
        components: {
            MemberUpdateLink,
        }, provide() {
            return {
                memberUpdateSetData: this.setData,
                memberUpdateFunc: this.memberUpdate,
            }
        },
        props: {
            memberType: {
                type: String,
            },
        },
        data() {
            return {
                updateLayout: '',
            }
        },
        beforeRouterEnter() {
        },
        created() {
            this.initConfig();
            /*this.getCategory();
            this.getDetail();*/
        },
        mounted() {

        },
        beforeDestroy() {
        },
        destroyed() {
        },
        computed: {
            ...mapState({
                UserInfo: state => state.userInfo.UserInfo,
            })
        },
        methods: {
            initConfig() {
                let memberConfig = this.returnMemberConfig(this.memberType);
                this.updateLayout = memberConfig.updateLayout;
                this.parsingData = memberConfig.parsingData;

            },
            setData(key, value) {
                this[key] = value;
            },
            memberUpdate() {
                let dataKeys = Object.keys(this.parsingData);
                console.log(dataKeys)
                this.$validate(dataKeys).then((success) => {
                    console.log(success)
                    if (!success) {
                        this.errorAlert('필수데이터를 확인해주세요.');
                        return false;
                    }
                    let params = {};
                    dataKeys.forEach(dataKey => {
                        params[this.parsingData[dataKey]] = this[dataKey];
                    })
                    try {
                        this.$api.$member.updateMember(params).then(res => res.Data.Result).then(res => {
                            let type = this.returnMemberUpdateType(res.Info.type);
                            if (type != true) {
                                this.errorAlert(type);
                                return false;
                            }
                            if (!util.isEmpty(this.files)) {
                                this.uploadFiles(this.files, 'profile', this.UserInfo.mb_no).then(success => {
                                    this.createAlert({
                                        title: '',
                                        content: '내정보가 수정되었습니다.',
                                        btnText: '확인',
                                        hide: () => {
                                            this.$store.dispatch('userInfo/setUserInfo', {token: this.$localStorage.get('token')});
                                        }
                                    });
                                })
                            } else {
                                this.createAlert({
                                    title: '',
                                    content: '내정보가 수정되었습니다.',
                                    btnText: '확인',
                                    hide: () => {
                                        this.$store.dispatch('userInfo/setUserInfo', {token: this.$localStorage.get('token')});
                                    }
                                });
                            }
                        })
                    } catch (e) {
                        console.log(e)

                    }
                });

            },
            returnMemberUpdateType(type) {
                switch (type) {
                    case 1:
                        return true;
                    case -3:
                        return "아이디는 수정할 수 없습니다";
                    case -4:
                        return "이름은 수정할 수 없습니다";
                    case -5:
                        return "이미 중복된 전화번호 입니다";
                    case -6:
                        return "생년월일은 수정할 수 없습니다";
                    case -7:
                        return "회원타입은 수정할 수 없습니다";
                    case -8:
                        return "탈퇴회원입니다";
                    case -9:
                        return "선택한 기관은 존재하지 않습니다";
                    default:
                        return "수정에 실패하였습니다. 잠시후 다시 시도주세요."

                }
            },
        },
        watch: {},
    }
</script>

<style scoped>

</style>