<template>
    <div>
        <member-filter-link :filterLayout="filterLayout" :filterData="filterSet.filter"></member-filter-link>
        <member-empty-link :emptyLayout="emptyLayout" v-if="List.length == 0"></member-empty-link>
        <member-list-link :listLayout="listLayout" :total="filterSet.page.total" :memberData="List"
                          v-else></member-list-link>
        <pagination-link :paginationLayout="paginationLayout" :type="'next'" :paginationData="filterSet.page"
                         :setPage="setPage" :listLength="List.length"></pagination-link>
    </div>
</template>

<script>
    // @ is an alias to /src
    import MemberListLink from "@/components/member/MemberListLink";
    import PaginationLink from "@/components/member/PaginationLink";
    import MemberFilterLink from "@/components/member/MemberFilterLink";
    import MemberEmptyLink from "@/components/member/MemberEmptyLink";
    import memberUtil from "@/components/member/memberUtil"
    import listMixins from "@/mixins/listMixins";
    import filterMixins from "@/mixins/filterMixins";
    import EventBus from "../../utils/event-bus";

    const {filterConfig} = memberUtil;
    export default {
        name: 'MemberListComponent',
        mixins: [filterMixins, listMixins, memberUtil],
        components: {
            MemberListLink,
            MemberEmptyLink,
            PaginationLink,
            MemberFilterLink
        },
        props: {
            memberType: {},
        },
        provide: function () {
            return {
                setPage: this.setPage,
                setFilter: this.setFilter,
                setData: this.setData,
            }
        },
        data() {
            return {
                listLayout: '',
                emptyLayout: '',
                filterLayout: '',
                paginationLayout: '',
                getFunc: null,
                listApiParamSet: {},
                pageRow: 10,
                noData: false,
                boardData: '',


                searchInput: '',
                searchInputOption: 'su',
                filterDate: '',
                filterType: '',
                filterJob: '',
            }
        },
        beforeRouterEnter() {
        },
        created() {
            this.initConfig();
            this.setFilterConfig(filterConfig)
            this.setParserType('url', this.$route.fullPath);
            EventBus.$on('removeMemberFunc', this.removeMemberList)

        },
        mounted() {
            this.getList(true)
        },
        beforeDestroy() {
            EventBus.$off('removeMemberFunc')
        },
        destroyed() {
        },
        computed: {},
        methods: {
            initConfig() {
                let memberConfig = this.returnMemberConfig(this.memberType);
                this.listLayout = memberConfig.listLayout;
                this.emptyLayout = memberConfig.emptyLayout;
                this.paginationLayout = memberConfig.paginationLayout;
                this.filterLayout = memberConfig.filterLayout;
                this.paginationType = memberConfig.paginationType;

                this.getFunc = memberConfig.getFunc;
                this.listApiParamSet = memberConfig.listApiParamSet;

                memberConfig.pageRow && memberConfig.pageRow != this.filterSet.page.size ? this.setPage('size', memberConfig.pageRow) : '';

                let listOptions = {
                    url: this.$api.$member[this.getFunc],
                    defaultParams: this.listApiParamSet
                }

                this.initList('api', listOptions)

            },
            setData(key, value) {
                this[key] = value;
            },
            removeMemberList(key) {
                let memberListIndex = this.List.findIndex(member => member.mb_no === key);
                if (memberListIndex < 0) {
                    return false
                }

                this.List.splice(memberListIndex, 1);

                this.filterSet.page.total = this.filterSet.page.total - 1;


                if (this.paginationType === 'lazyload' && (this.List.length < this.filterSet.page.total - 1)) {
                    let params = JSON.parse(JSON.stringify(this.listApiParamSet));
                    params.mb_no = this.memberKey;
                    params.page = this.filterSet.page.page * this.pageRow + 1;
                    this.$api.$member[this.getFunc](params).then(res => res.Data.Result).then(res => {
                        if (res.Info.type === 1 && res.List.length > 0) {
                            this.List.push(res.List[0]);
                        }
                    })
                }
            }


        },
        watch: {},
    }
</script>
