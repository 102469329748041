<template>
    <component :is="component" :paginationData="paginationData" :setPage="setPage" :type="type" :setData="setData" :listLength="listLength" v-if="component" />
</template>
<script>
    export default {
        name: 'PaginationLink',
        mixins: [],
        components: {
        },
        props: {
            paginationLayout: {

            },
            paginationData: {
                type: Object,
                default: {
                    total: 0,
                    page: 0,
                    size: 0,
                }
            },
            setPage: {
                type: Function,
                default: () => {},
            },
            setData: {
                type: Function,
                default: () => {},
            },
            type: {},
            listLength: {
                type: Number,
                default: 0
            }
        },
        data() {
            return {
                component: null,
            }
        },
        beforeRouterEnter() {},
        created() {
        },
        mounted() {
            this.loader()
                .then(() => {
                    this.component = () => this.loader()
                })
                .catch(() => {
                    this.component = () => import('@/template/member/pagination/PaginationtDefaultLayout')
                })

        },
        beforeDestroy() {
        },
        destroyed() {},
        computed: {
            loader() {
                if (!this.paginationLayout) {
                    return null
                }
                return () => import(`@/template/member/pagination/${this.paginationLayout}`)
            },
        },
        methods:{

        },
        watch: {

        },
    }
</script>