<template>
    <component :is="inputComponent" :commentData="commentData" v-if="inputComponent" />
</template>

<script>
    // @ is an alias to /src
    import boardTemplateUtil from "@/components/board/boardTemplateUtil"
    import boardFilterUtil from "@/components/board/boardFilterUtil"
    import {mapGetters, mapState} from "vuex";
    import EventBus from "@/utils/event-bus";
    import VuxAlert from "@/mixins/vux-alert"

    export default {
        name: 'CommentWriteComponent',
        mixins: [ boardTemplateUtil, boardFilterUtil, VuxAlert ],
        components: {
        },
        provide() {
            return{
                commentSetData: this.setData,
                writeComment: this.writeComment,
            }
        },
        props: {
            boardType: {
                type: String,
                default: '',
            },
            commentData: {
                default: () => {return {}}
            },
        },
        data() {
            return{
                commentInputLayout: '',
                inputComponent: null,
                writeFunc: '',
                writeApiParamSet: '',
                keyName: '',
                pageType: '',
                modalName: '',

                commentTxt: '',
            }
        },
        beforeRouterEnter() {},
        created() {
            this.commentTxt = this.commentData.wr_content;

            let configs = this.returnBoardTemplateConfig(this.boardType);
            this.commentInputLayout = configs.commentInputLayout;

            let boardFilterConfig = this.returnBoardFilterConfig(this.boardType);
            this.writeFunc = boardFilterConfig.writeFunc;
            this.writeApiParamSet = boardFilterConfig.writeApiParamSet;
            this.keyName = boardFilterConfig.keyName;
            this.pageType = boardFilterConfig.pageType;
            this.modalName = boardFilterConfig.modalName;
        },
        mounted() {
            if(this.inputLoader != null) {
                this.inputLoader()
                    .then(() => {
                        this.inputComponent = () => this.inputLoader()
                    })
                    .catch(() => {
                        this.inputComponent = () => import('@/template/board/comment/CommentInputDefaultLayout')
                    })
            }

        },
        beforeDestroy() {
        },
        destroyed() {},
        computed: {
            inputLoader() {
                if (!this.commentInputLayout) {
                    return null
                }
                return () => import(`@/template/board/comment/${this.commentInputLayout}`)
            },
        },
        methods:{
            setData(key, data) {
                this[key] = data;
            },
            writeComment() {
                let params = JSON.parse(JSON.stringify(this.writeApiParamSet));
                params.wr_content = this.commentTxt;
                params.wr_id = this.commentData.wr_id;
                try {
                    this.$api.$board[this.writeFunc](params).then(res => res.Data.Result).then(res => {
                        let type = this.returnCommentWriteType(res.Info.type);
                        if(type != true) {
                            this.errorAlert(type);
                            return false;
                        }
                        let comment = this.commentData;
                        comment.wr_content = this.commentTxt;
                        EventBus.$emit('modifyCommentAfterFunc', comment);
                        this.createAlert({
                            title: '',
                            content: '등록되었습니다.',
                            btnText: '확인',
                            hide: () => {
                                if(this.pageType === 'modal') {
                                    this.$modal.hide(this.modalName);
                                }else{
                                    this.$router.back();
                                }
                            }
                        })
                    })
                }catch (e) {
                    console.log(e);
                }
            },
            returnCommentWriteType(type) {
                switch (type) {
                    case 1:
                        return true;
                    case -2:
                        return '권한이 없습니다.'
                    case -5:
                        return '금지어가 포함되어있습니다.'
                    case -7:
                        return '댓글이 존재하지 않습니다.'
                    default:
                        return '댓글 수정에 실패하였습니다. 잠시후 다시 시도해주세요.'
                }
            },
        },
        watch: {

        },
    }
</script>
