<template>
    <div class="main_section2_bg">
        <div class="main_section2 main_content" v-if="mentorList.length > 0">
            <div class="section2_title"></div>
            <swiper ref="mySwiper" :options="swiperOptions">
                <swiper-slide v-for="(item, index) in mentorList" :key="index">
                    <div class="slide_pic">
                        <img :src="`${item.Profile[0].org_url}?${setImageOptions(185,185,'jpg')}`"
                             class="slide_img" alt="">
                    </div>
                    <div class="slide_name">{{item.mb_name}}</div>
                    <div class="slide_job pb0" v-html="item.mb_belong"></div>
                    <div class="slide_job" v-html="item.mb_job"></div>
                    <div class="slide_description" v-html="item.mb_memo"></div>
                </swiper-slide>
            </swiper>
            <div class="swiper-button-prev btn_prev" slot="button-prev"></div>
            <div class="swiper-button-next btn_next" slot="button-next"></div>
        </div>
    </div>
</template>

<script>
    import imageOption from "@/mixins/imageOption";
    import {Swiper, SwiperSlide, directive} from 'vue-awesome-swiper'
    import 'swiper/css/swiper.css'

    export default {
        name: "MemberListSlideLayout",
        mixins:[imageOption],
        mounted() {
        },
        afterMount(){
            this.check();
        },
        components: {
            Swiper,
            SwiperSlide
        },
        props:{},
        created() {
            this.$api.$member.getMentor({opf:1,ip:0,di:1}).then(res => res.Data.Result).then(res => {
                this.mentorList = res.List.reverse();
            })
        },
        data() {
            return {
                swiperOptions: {
                    slidesPerView: 5,
                    spaceBetween: 28.75,
                    slidesPerGroup: 3,
                    centeredSlides: false,
                    loop: true,
                    loopFillGroupWithBlank: false,
                    pagination: {
                        el: '.swiper-pagination',
                        clickable: true
                    },
                    navigation: {
                        nextEl: '.swiper-button-next',
                        prevEl: '.swiper-button-prev'
                    },
                    // Some Swiper option/callback...
                },
                mentorList:[],
            }
        },
        computed: {
            swiper() {
                return this.$refs.mySwiper.$swiper
            }
        },
        directives: {
            swiper: directive
        },
        methods : {
            memoReturn(value){
                return value.replace(/\n/gi,"<br/>").replace(/ /gi,"&nbsp;");
            },
        }
    }
</script>

<style scoped>

</style>
