<template>
    <div v-if="adsList.length > 0" class="popup_window" v-show="has_haedAds">
        <div class="popup_wrap" v-for="ads in adsList" :key="`ads${ads.p_idx}`" :id="`ads${ads.p_idx}`">
            <div class="popup">
                <div class="popup_img" :style="`background-image: url(${returnBannerImage(ads)})`"
                     @click="goDetail(ads.p_idx)"></div>
                <div class="popup_close" @click="hideAds(ads.p_idx)"></div>
                <div class="popup_text" @click="hideTodayAds(ads.p_idx)">하루동안 보지않기</div>
            </div>
        </div>
    </div>
</template>
<script>
    import EventBus from "../../utils/event-bus";
    import VuxAlert from "@/mixins/vux-alert";
    import imageOption from "@/mixins/imageOption";
    export default {
        name: "BannerComponent",
        mixins: [VuxAlert, imageOption],
        components: {

        },
        props: {

        },
        data() {
            return{
                adsList: [],
                has_haedAds: false,
            }
        },
        beforeRouterEnter() {},
        created() {
            this.getBanner();
        },
        mounted() {

        },
        beforeDestroy() {
        },
        destroyed() {},
        computed: {
        },
        methods:{
            getBanner() {
                let params = {
                    fi: 1,
                    ip: 0,
                    isdi:1,
                }
                this.$api.$banner.getBanner(params).then(res => res.Data.Result).then(res => {
                    if(res.Info.type == 1) {
                        let today = this.$moment().format('YYYY-MM-DD');
                        this.adsList = res.List;
                        if(this.adsList.length > 0) {
                            this.$forceUpdate();
                            let localHideAds = this.$localStorage.get('SafePcCheckHideAd');
                            let $this = this;
                            let newLocalHideAds = [];
                            window.setTimeout(() => {
                                this.has_haedAds = true;
                                if(localHideAds != null && localHideAds.length > 0) {
                                    localHideAds.forEach(ads => {
                                        if(ads.date === today) {
                                            let adsDiv = document.getElementById(`ads${ads.p_idx}`);
                                            console.log(`#ads${ads.p_idx}`)
                                            console.log(adsDiv)
                                            if(adsDiv) {adsDiv.style.display = 'none';}
                                        }
                                        if($this.$moment(ads.date).isSameOrAfter(today)) {
                                            newLocalHideAds.push(ads);
                                        }
                                    })
                                    this.$localStorage.set('SafePcCheckHideAd', newLocalHideAds);
                                }
                            }, 500);
                        }
                    }
                })
            },
            hideTodayAds(p_idx) {
                let localHideAds = this.$localStorage.get('SafePcCheckHideAd');
                if(localHideAds != null && localHideAds.length > 0) {
                    let adsWrap = localHideAds.filter(ads => ads.p_idx === p_idx);
                    if(adsWrap.length > 0) {
                        localHideAds.forEach(ads => {
                            if(ads.p_idx === p_idx) {
                                ads.date = this.$moment().format('YYYY-MM-DD')
                            }
                        });
                    }else{
                        let hideAds = {
                            date : this.$moment().format('YYYY-MM-DD'),
                            p_idx: p_idx
                        }
                        localHideAds.push(hideAds);
                    }

                }else{
                    localHideAds = [{
                        date : this.$moment().format('YYYY-MM-DD'),
                        p_idx: p_idx
                    }]
                }
                this.$localStorage.set('SafePcCheckHideAd', localHideAds);
                let adsDiv = document.querySelector(`#ads${p_idx}`)
                adsDiv.style.display = 'none';
            },
            hideAds(p_idx) {
                let adsDiv = document.querySelector(`#ads${p_idx}`)
                adsDiv.style.display = 'none';
            },
            returnBannerImage(banner) {
                return banner.hasOwnProperty('File') && banner.File.hasOwnProperty('popupPC') ? `${banner.File.popupPC.org_url}?${this.setImageOptions(499, 268, 'png')}` : ''
            },
            setData(key, value) {
                this[key] = value;
            },
            goDetail(idx) {
                this.$router.push(`/banner/${idx}`)
            },

        },
        watch: {

        },
    }
</script>

<style scoped>

</style>