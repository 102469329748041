<template>
<modal name="notice-detail-modal" class="notice-detail-modal vh100"
           :width="'100%'"
           :height="'100%'"
           :pivotY="0"
           :transition="`modal-bottom`"
    >
        <div>
<!--            <page-header :BackEvent="backEvent"></page-header>-->
            <div>
                <board-detail-component :boardType="'noticeDetail'" :boardKey="boardKey"></board-detail-component>
            </div>
            <button class="close-btn" @click="backEvent">
                <i class="el-icon-close"></i>
            </button>
        </div>
    </modal>
</template>

<script>
    import BoardDetailComponent from "@/components/board/BoardDetailComponent";
    export default {
        name: "NoticeDetailModal",
        mixins: [],
        components: {
            BoardDetailComponent
        },
        props: {
            boardKey: {},
        },
        data() {
            return {
            }
        },
        beforeRouterEnter() {},
        created() {
            //EventBus.$on
        },
        mounted() {
            //EventBus.$on('')
        },
        beforeDestroy() {
        },
        destroyed() {},
        computed: {
        },
        methods:{
            backEvent() {
                this.$modal.hide('notice-detail-modal')
            },

        },
        watch: {

        },
    }
</script>

<style lang="scss">

</style>