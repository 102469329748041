<template>
    <modal name="mentor-sel-modal" class="mentor-sel-modal"
           :width=365
           :height=545
           :adaptive="true"
           :transition="`modal-bottom`"
           :clickToClose="false"
           @before-open="handlerBeforeOpen"
           @before-close="handlerBeforeClose"
    >
        <div class="mentor-select-wrap">
            <div class="title-wrap">
                <div class="title">멘토선택</div>
                <div class="text">
                    내 글을 꼭 봐주길 원하는 멘토를 선택하세요<br>
                    최대 3명의 멘토 선택이 가능합니다<br>
                    지정멘토 외에 다른 멘토들도 글 확인이 가능합니다
                </div>
            </div>
            <div class="member-wrap scroll-content">
                <member-list-component :memberType="'mentorSel'"></member-list-component>
            </div>
        </div>
        <div class="button-wrap">
            <button class="selected" @click="saveMentor(true)">선택완료</button>
            <button class="cancel" @click="saveMentor(false)">취소</button>
        </div>
    </modal>
</template>

<script>
    import EventBus from "@/utils/event-bus";
    import MemberListComponent from "@/components/member/MemberListComponent";

    export default {
        name: "MentorSelModal",
        mixins: [],
        components: {
            MemberListComponent
        },
        provide() {
            const mentorSelList = {}
            Object.defineProperty(mentorSelList, 'list', {
                enumerable: true,
                get: () => this.mentors,
            });
            return{
                // mentorSelList: this.mentors,
                mentorSelList,
                setMentorList: this.setMentorList,
            }
        },
        props: {
            mentorList: {
                default: [],
            },
        },
        data() {
            return {
                mentors: [],
            }
        },
        beforeRouterEnter() {},
        created() {
            //EventBus.$on
        },
        mounted() {
            //EventBus.$on('')
        },
        beforeDestroy() {
        },
        destroyed() {},
        computed: {

        },
        methods:{
            backEvent() {
                this.$modal.hide('mentor-sel-modal')
            },
            handlerBeforeOpen() {
                this.mentors = this.mentorList.slice();
                this.scrollDisable();
            },
            handlerBeforeClose() {
                this.$router.replace(this.$route.path);
                this.mentors = [];
                this.scrollAble();
            },
            setMentorList(list) {
                this.mentors = list;
            },
            saveMentor(hasSave) {
                let mentors = false;
                if(hasSave) {
                    mentors = this.mentors;
                }
                EventBus.$emit('selectMentor', mentors);
                this.backEvent();
            },
            scrollDisable(){
                $('html, body').addClass('hidden');
            },
            scrollAble(){
                $('html, body').removeClass('hidden');

            }
        },
        watch: {

        },
    }
</script>

<style lang="scss">

</style>