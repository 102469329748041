<template>
    <modal name="update-pw-modal" class="update-modal"
           :width=285
           :height=434
           :adaptive="true"
           :transition="`modal-bottom`"
           @before-open="scrollDisable"
           @before-close="scrollAble"
    >
        <member-update-pw-component></member-update-pw-component>
        <button class="update-btn" @click="clickBtn()">저장</button>
    </modal>
</template>

<script>
    import EventBus from "@/utils/event-bus";
    import MemberUpdatePwComponent from "@/components/member/MemberUpdatePwComponent";
    export default {
        name: "UpdatePwModal",
        mixins: [],
        components: {
            MemberUpdatePwComponent
        },
        props: {

        },
        data() {
            return {

            }
        },
        beforeRouterEnter() {},
        created() {
            //EventBus.$on
        },
        mounted() {
            //EventBus.$on('')
        },
        beforeDestroy() {
        },
        destroyed() {},
        computed: {

        },
        methods:{
            backEvent() {
                this.$modal.hide('update-pw-modal')
            },
            clickBtn() {
                EventBus.$emit('updatePassword')
            },
            scrollDisable(){
                $('html, body').addClass('hidden');
            },
            scrollAble(){
                $('html, body').removeClass('hidden');

            }

        },
        watch: {

        },
    }
</script>

<style lang="scss">

</style>