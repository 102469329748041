<template>
    <component :is="component" :filterData="filterData" v-if="component"  />
</template>
<script>
    export default {
        name: 'BoardFilterLink',
        mixins: [],
        components: {
        },
        props: {
            filterLayout: {},
            filterData: {
                default: {
                    categorys: [],
                }
            },
        },
        data() {
            return {
                component: null,
            }
        },
        beforeRouterEnter() {},
        created() {
        },
        mounted() {
            this.filterLoader();
        },
        beforeDestroy() {
        },
        destroyed() {},
        computed: {
            loader() {
                if (!this.filterLayout) {
                    return null
                }
                return () => import(`@/template/board/filter/${this.filterLayout}`)
            },
        },
        methods: {
            filterLoader() {
                this.loader()
                    .then(() => {
                        this.component = () => this.loader()
                    })
                    .catch(() => {
                        this.component = () => import('@/template/board/filter/BoardFilterDefaultLayout')
                    })

            },
        },
        watch: {
            'filterLayout': {
                deep: true,
                handler: function () {
                    this.filterLoader();
                }
            }
        },
    }
</script>