const configMember = {
    adminHome: {
        listLayout: 'MemberListDefaultLayout',
        emptyLayout: 'MemberEmptyDefaultLayout',
        paginationLayout: 'PaginationtDefaultLayout',
        filterLayout: 'MemberFilterTypeLayout',
        getFunc: 'getMemberList',
        getCategoryFunc: null,
        pageRow: 10,
        paginationType: 'lazyload',
        listApiParamSet: {
            opf: 1,
            ty: '1',
            isv : 0,
            m_type: 'normal',
            ip:1,
        },
        filterData: {},
    },
    adminHomeDetail: {
        detailLayout: 'MemberDetailPageLayout',
        detailApiParamSet:{
            opf: 1,
            m_type: 'normal',
            opd:1,
        },
        getFunc: 'getMemberList',
        getCategoryFunc: null,
        paginationType: 'lazyload',
        filterData: {},
    },
    modalMemberInfo: {
        detailLayout: 'MemberDetailDefaultLayout',
        getFunc: 'getMemberList',
        paginationType: 'lazyload',

        detailApiParamSet:{
            m_type:'normal',
            ip:0,
            ot: 1,
            opf:1,

        },
    },
    mentorSel: {
        listLayout: 'MemberListSelectLayout',
        emptyLayout: 'MemberEmptyDefaultLayout',
        paginationLayout: 'LazyloadLoadingLayout',
        filterLayout: 'MemberFilterDefaultLayout',
        getFunc: 'getMentor',
        getCategoryFunc: null,
        pageRow: 10,
        paginationType: 'lazyload',
        listApiParamSet: {
            ip:1,
            opf: 1,
            ty: 1,

        },
    },
    update: {
        updateLayout: 'MemberUpdateDefaultLayout',
        parsingData: {
            pushAlarm: 'mb_alarm',
            email: 'mb_email',
            job: 'mb_job',
            belong: 'mb_belong',
            alarmJob: 'mjc_idx',
            introduce: 'mb_memo',
        },
    },
    updateMentee: {
        updateLayout: 'MemberUpdateMenteeLayout',
        parsingData: {
            pushAlarm: 'mb_alarm',
            email: 'mb_email',
            introduce: 'mb_memo',
        },
    },
}

export  default {
    methods: {
        returnMemberConfig(type) {
            return configMember[type];
        }
    },
    filterConfig: {
        cr: {
            type: 'between',
            classname: 'filterDate',
        },
        ty: {
            type: 'match',
            classname: 'filterType',
        },
        na: {
            type: 'query',
            classname: 'filterInput',
        },
        jid: {
            type: 'match',
            classname: 'filterJob'
        },
    }
}
