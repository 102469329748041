const configBoardTemplate = {
    letter: {
        listLayout: 'BoardListLetterLayout',
        emptyLayout: 'BoardEmptyDefaultLayout',
        paginationLayout: 'PaginationtDefaultLayout',
        filterLayout: 'BoardFilterLetterLayout'
    },
    letterListLike: {
        likeLayout: 'BoardLikeDefaultLayout',
    },
    letterDetail: {
        detailLayout: 'BoardDetailLetterLayout',
    },
    letterLike: {
        likeLayout: 'BoardLikeDefaultLayout',
    },
    letterComment: {
        commentListLayout: 'CommentListDefaultLayout',
        commentEmptyLayout: 'CommentEmptyLayout',
        commentInputLayout: 'CommentInputLetterLayout',
        commentPaginationLayout: 'CommentPaginationtDefaultLayout',
    },
    letterCommentModify: {
        commentInputLayout: 'CommentInputLetterModifyLayout',
    },
    letterSearch: {
        listLayout: 'BoardListLetterLayout',
        emptyLayout: 'BoardEmptySearchLayout',
        paginationLayout: 'PaginationtDefaultLayout',
        filterLayout: 'BoardFilterLetterSearchLayout'
    },
    myCommentLetter: {
        listLayout: 'BoardListLetterLayout',
        emptyLayout: 'BoardEmptyDefaultLayout',
        paginationLayout: 'PaginationtDefaultLayout',
        filterLayout: 'BoardFilterNoneLayout'
    },
    myLikeLetter: {
        listLayout: 'BoardListLetterLayout',
        emptyLayout: 'BoardEmptyDefaultLayout',
        paginationLayout: 'PaginationtDefaultLayout',
        filterLayout: 'BoardFilterNoneLayout'
    },
    commu: {
        listLayout: 'BoardListCommuLayout',
        emptyLayout: 'BoardEmptyDefaultLayout',
        paginationLayout: 'PaginationtDefaultLayout',
        filterLayout: 'BoardFilterCommuHomeLayout'
    },
    commuListLike: {
        likeLayout: 'BoardLikeCommuListLayout',
    },
    commuHome: {
        listLayout: 'BoardListCommuLayout',
        emptyLayout: 'BoardEmptyDefaultLayout',
        paginationLayout: 'PaginationtDefaultLayout',
        filterLayout: 'BoardFilterCommuHomeLayout'
    },
    commuDetail: {
        detailLayout: 'BoardDetailCommuLayout',
    },
    commuComment: {
        commentListLayout: 'CommentListDefaultLayout',
        commentEmptyLayout: 'CommentEmptyLayout',
        commentInputLayout: 'CommentInputDefaultLayout',
        commentPaginationLayout: 'CommentPaginationtDefaultLayout',
    },
    commuCommentModify: {
        commentInputLayout: 'CommentInputLetterModifyLayout',
    },
    commuWrite: {
        writeLayouts: ['BoardWriteSecretLayout', 'BoardWriteInputLayout', 'BoardWriteFileLayout', 'BoardWriteMentorLayout']
    },
    commuUpdate: {
        writeLayouts: ['BoardWriteSecretUpdateLayout', 'BoardWriteInputLayout', 'BoardWriteFileLayout', 'BoardWriteViewMentorLayout']
    },
    myCommentCommu: {
        listLayout: 'BoardListCommuLayout',
        emptyLayout: 'BoardEmptyDefaultLayout',
        paginationLayout: 'PaginationtDefaultLayout',
        filterLayout: 'BoardFilterNoneLayout'
    },
    myLikeCommu: {
        listLayout: 'BoardListCommuLayout',
        emptyLayout: 'BoardEmptyDefaultLayout',
        paginationLayout: 'PaginationtDefaultLayout',
        filterLayout: 'BoardFilterNoneLayout'
    },
    myMemteeCommu: {
        listLayout: 'BoardListCommuLayout',
        emptyLayout: 'BoardEmptyDefaultLayout',
        paginationLayout: 'PaginationtDefaultLayout',
        filterLayout: 'BoardFilterNoneLayout'
    },
    notice: {
        listLayout: 'BoardListDefaultLayout',
        emptyLayout: 'BoardEmptySearchLayout',
        paginationLayout: 'PaginationtDefaultLayout',
        filterLayout: 'BoardFilterDefaultLayout'
    },
    noticeDetail: {
        detailLayout: 'BoardDetailDefaultLayout',
    },
    faq: {
        listLayout: 'BoardListCollapseLayout',
        emptyLayout: 'BoardEmptyDefaultLayout',
        paginationLayout: 'PaginationtDefaultLayout',
        filterLayout: 'BoardFilterFaqLayout'
    },
    mentor: {
        listLayout: 'BoardListMentorCommuLayout',
        emptyLayout: 'BoardEmptyDefaultLayout',
        paginationLayout: 'PaginationtDefaultLayout',
        filterLayout: 'BoardFilterMentorCommuLayout'
    },
    mentorListLike: {
        likeLayout: 'BoardLikeMentorCommuLayout',
    },
    mentorLike: {
        likeLayout: 'BoardLikeMentorCommuLayout',
    },
    mentorDetail: {
        detailLayout: 'BoardDetailMentorCommuLayout',
    },
    mentorComment: {
        commentListLayout: 'CommentListDefaultLayout',
        commentEmptyLayout: 'CommentEmptyLayout',
        commentInputLayout: 'CommentInputDefaultLayout',
        commentPaginationLayout: 'CommentPaginationtDefaultLayout',
    },
    mentorCommentModify: {
        commentInputLayout: 'CommentInputLetterModifyLayout',
    },
    mentorWrite: {
        writeLayouts: ['BoardWriteMentorInputLayout', 'BoardWriteFileLayout']
    },
    mentorUpdate: {
        writeLayouts: ['BoardWriteMentorInputLayout', 'BoardWriteFileLayout']
    },
    myCommentMentor: {
        listLayout: 'BoardListMentorCommuLayout',
        emptyLayout: 'BoardEmptyDefaultLayout',
        paginationLayout: 'PaginationtDefaultLayout',
        filterLayout: 'BoardFilterNoneLayout'
    },
    myLikeMentor: {
        listLayout: 'BoardListMentorCommuLayout',
        emptyLayout: 'BoardEmptyDefaultLayout',
        paginationLayout: 'PaginationtDefaultLayout',
        filterLayout: 'BoardFilterNoneLayout'
    },
    myPostMentor:{
        listLayout: 'BoardListMentorCommuLayout',
        emptyLayout: 'BoardEmptyDefaultLayout',
        paginationLayout: 'PaginationtDefaultLayout',
        filterLayout: 'BoardFilterNoneLayout'
    },
    amidan: {
        listLayout: 'BoardListAmidanLayout',
        emptyLayout: 'BoardEmptySearchLayout',
        paginationLayout: 'PaginationtDefaultLayout',
        filterLayout: 'BoardFilterAmidanLayout'
    },
    amidanDetail: {
        detailLayout: 'BoardDetailDefaultLayout',
    },

}
export default {
    methods: {
        returnBoardTemplateConfig(type) {
            return configBoardTemplate[type];
        }
    }
}
