<template>
    <board-detail-link :detailLayout="detailLayout" :boardData="boardData"></board-detail-link>
</template>

<script>
    import boardTemplateUtil from "@/components/board/boardTemplateUtil"
    import boardFilterUtil from "@/components/board/boardFilterUtil"
    import EventBus from "@/utils/event-bus";
    import {mapGetters, mapState} from "vuex";
    import BoardDetailLink from "@/components/board/BoardDetailLink";
    import vuxAlert from "@/mixins/vux-alert";
    import util from "@/mixins/util";

    export default {
        name: "BoardDetailComponent",
        mixins: [boardTemplateUtil, boardFilterUtil, vuxAlert],
        components: {
            BoardDetailLink
        },
        provide() {
            return {
                afterLikeFunc: this.toggleLikeStatus,
                afterCommentWriteFunc: this.addCommentCount,
                afterCommentDeleteFunc: this.deleteCommentCount,
                boardDelete: this.boardDelete,
                boardBlind: this.boardBlind,
                checkMyBoard: this.checkMyBoard,
            }
        },
        props: {
            boardType: {
                type: String,
            },
            boardKey: {}
        },
        data() {
            return{
                getFunc: null,
                getCategoryFunc: null,
                detailLayout: '',
                detailApiParamSet: {},
                deleteFunc: '',
                deleteApiParamSet: {},
                detailType: 'page',
                modalName: '',
                blindFunc: '',
                keyName: '',

                boardData: {},
            }
        },
        beforeRouterEnter() {},
        created() {
            this.initConfig();
            this.getCategory();
            this.getDetail();
        },
        mounted() {

        },
        beforeDestroy() {
        },
        destroyed() {},
        computed: {
            ...mapState({
                UserInfo: state => state.userInfo.UserInfo,
            }),
        },
        methods:{
            initConfig() {
                let boardTemplateConfig = this.returnBoardTemplateConfig(this.boardType);
                this.detailLayout = boardTemplateConfig.detailLayout;
                let boardFilterConfig = this.returnBoardFilterConfig(this.boardType);
                this.getFunc = boardFilterConfig.getFunc;
                this.getCategoryFunc = boardFilterConfig.getCategoryFunc;
                this.detailApiParamSet = boardFilterConfig.detailApiParamSet;
                this.deleteFunc = boardFilterConfig.deleteFunc;
                this.deleteApiParamSet = boardFilterConfig.deleteApiParamSet;
                this.detailType = boardFilterConfig.detailType;
                this.modalName =  boardFilterConfig.modalName;
                this.blindFunc = boardFilterConfig.blindFunc;
                this.keyName = boardFilterConfig.keyName;
            },
            getCategory() {
                if(!this.getCategoryFunc) {
                    return
                }
                this.$api.$board[this.getCategoryFunc](params).then(res => res.Data.Result).then(res => {
                    if(res.Info.Type < 0) return;
                    this.categorys = res.List;
                })
                return;
            },
            getDetail() {
                let params = JSON.parse(JSON.stringify(this.detailApiParamSet))
                params.id = this.boardKey;
                this.$api.$board[this.getFunc](params).then(res => res.Data.Result).then(res => {
                    if(res.Info.type != 1) {
                        this.createAlert({
                            content: '게시글을 불러올 수 없습니다.',
                            btnText: '확인',
                            hide: () => {
                                if(this.detailType === 'page') {
                                    this.$root.$children[0].hasBack = true;
                                    this.$router.go(-1);
                                }else if(this.detailType === 'modal' && !util.isEmpty(this.modalName)) {
                                    this.$modal.hide(this.modalName);
                                }
                            }
                        })
                        return false;
                    }
                    this.boardData = res.List[0];
                })
            },
            boardDelete() {
                let params = JSON.parse(JSON.stringify(this.deleteApiParamSet));
                params[this.keyName] = [this.boardKey];
                try {
                    this.$api.$board[this.deleteFunc](params).then(res => res.Data.Result).then(res => {
                        let type = this.returnBoardDeleteType(res.Info.type);
                        if(type === true) {
                            EventBus.$emit('boardListDeleteAfterFunc', this.keyName, Number(this.boardKey));
                            this.createAlert({
                                title: '',
                                content: '게시글이 삭제되었습니다.',
                                btnText: '확인',
                                hide: () => {
                                    if(this.detailType === 'page') {
                                        this.$root.$children[0].hasBack = true;
                                        this.$router.go(-1);
                                    }else{
                                        this.$modal.hide(this.modalName);
                                    }
                                }
                            })
                            return false;
                        }
                        this.errorAlert(type);
                    })
                }catch (e) {
                    console.log(e)
                }
            },
            returnBoardDeleteType(type) {
                switch (type) {
                    case 1:
                        return true;
                    case -2:
                        return '권한이 없습니다.';
                    case -7:
                        return '이미 삭제된 게시글입니다.';
                    default:
                        return '게시글 삭제에 실패하엿습니다. 잠시후 다시 시도해주세요.';
                }
            },
            boardBlind(board, text) {
                let params = {
                    bb_reason: text
                }
                params[this.keyName] = this.boardKey;
                try {
                    this.$api.$board[this.blindFunc](params).then(res => res.Data.Result).then(res => {
                        let type = this.returnBoardBlindType(res.Info.type);
                        if(type === true) {
                            this.createAlert({
                                title: '',
                                content: '신고완료',
                                btnText: '확인',
                            })
                        }else{
                            this.errorAlert(type);
                        }
                    })
                }catch (e) {
                    console.log(e)
                }
            },
            returnBoardBlindType(type) {
                switch (type) {
                    case 1:
                        return true;
                    case -2:
                        return '이미 신고하신 게시글입니다.';
                    case -403:
                    case -404:
                        return '이미 삭제된 게시글입니다.';
                    default:
                        return "신고처리가 실패하였습니다. 잠시 후 다시 시도해주세요.";
                }
            },
            setData(key, value) {
                this[key] = value;
            },
            toggleLikeStatus() {
                if(!this.boardData.hasOwnProperty('is_like') || !this.boardData.hasOwnProperty('wr_good')) {
                    return;
                }
                if(this.boardData.is_like) {
                    this.boardData.is_like = false;
                    this.boardData.wr_good = this.boardData.wr_good - 1;
                }else{
                    this.boardData.is_like = true;
                    this.boardData.wr_good = this.boardData.wr_good + 1;
                }
            },
            addCommentCount() {
                this.boardData.CommentCount = this.boardData.CommentCount + 1;
            },
            deleteCommentCount() {
                this.boardData.CommentCount = this.boardData.CommentCount - 1;
            },
            checkMyBoard() {
                return !util.isEmpty(this.boardData.Writer) && this.UserInfo.mb_no === this.boardData.Writer.mb_no;
            },
        },
        watch: {

        },
    }
</script>

<style scoped>

</style>