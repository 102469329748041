<template>
    <modal name="parental-consent-modal" class="parental-consent-modal"
           :width=365
           :height=500
           :adaptive="true"
           @before-open="scrollDisable"
           @before-close="scrollAble"
    >
        <div class="parent-agree">
            <div class="title-wrap">
                <div class="title">보호자 동의</div>
                <div class="text">만14세미만 사용자의경우 보호자의 동의가 필요합니다</div>
            </div>
            <div class="content-wrap">
                <div class="form">
                    <div class="form-data">
                        <div class="title">보호자이름</div>
                        <div class="value">
                            <input type="text" placeholder="이름입력" v-model="parentName" @input="parentSetData('parentName', parentName)" maxLength="5"></input>
                        </div>
                        <div class="form-caption" v-if="validation.hasError('parentName')" v-html="validation.firstError('parentName')"></div>
                    </div>
                    <div class="form-data birth">
                        <div class="title">보호자<br>생년월일</div>
<!--                        <el-select class="select year" placeholder="년도">
                            <el-option>년도</el-option>
                        </el-select>
                        <el-select class="select" placeholder="월">
                            <el-option>월</el-option>
                        </el-select>
                        <el-select class="select" placeholder="일">
                            <el-option>일</el-option>
                        </el-select>-->
                        <el-date-picker
                                class="select"
                                v-model="parentBirth"
                                type="date"
                                placeholder="생년월일"
                                value-format="yyyy-MM-dd"
                                :picker-options="pickerOptions"
                                :clearable="false"
                                :prefix-icon="'d'"
                                @change="parentSetData('parentBirth', parentBirth)"
                        >
                        </el-date-picker>
                        <div class="form-caption" v-if="validation.hasError('parentBirth')" v-html="validation.firstError('parentBirth')"></div>
                    </div>
                    <div class="form-data">
                        <div class="title">전화번호</div>
                        <div class="input check">
                            <input type="tel" class="value" placeholder="’-’ 제외, 숫자만 입력" maxLength="11" v-model="parentPhone" @input="parentSetData('parentPhone', parentPhone)"></input>
                            <button class="send" @click="checkSamePhone">인증번호 전송</button>
                        </div>
                        <div class="form-caption" v-if="validation.hasError('parentPhone')">{{validation.firstError('parentPhone')}}</div>
                        <div class="form-caption" v-else-if="sameParentPhone">가입자와 동일한 전화번호 입니다.</div>
                        <div class="form-caption" v-else-if="parentPhoneOverlap">중복된 전화번호 입니다.</div>
                    </div>
                    <div class="form-data" v-if="parentPhoneCertifyAuth != ''">
                        <div class="title">인증번호<br>입력</div>
                        <div class="input check">
                            <input type="tel" class="value" placeholder="숫자만입력" v-model="parentPhoneCertify" @input="parentSetData('parentPhoneCertify', $event.target.value)"></input>
                        </div>
                        <div class="form-caption" v-if="validation.hasError('parentPhoneCertify')">{{validation.firstError('parentPhoneCertify')}}</div>
                        <div class="form-caption" v-else-if="validation.hasError('parentPhoneCertifyAuth')">{{validation.firstError('parentPhoneCertifyAuth')}}</div>
                        <div class="form-caption" v-else-if="parentPhoneCertifyFail">인증번호가 일치하지 않습니다</div>
                    </div>
                </div>
                <div class="form-check">
                    <input type="checkbox" id="agree" :value="true" v-model="parentAccept"></input>
                    <label for="agree">보호자의 동의를 받았습니다</label>
                    <div class="form-warning">
                        허위정보로 작성할 경우 생기는 불이익에 대해서는<br>
                        책임지지 않습니다.
                    </div>
                    <div class="form-caption" v-show="hasError">모두 입력해주세요</div>
                </div>
            </div>
            <button class="modal_btn agree_btn" @click="validateCheck()" :disabled="registerBtnDisabled">동의완료</button>
        </div>
    </modal>
</template>

<script>
    import EventBus from "@/utils/event-bus";
    import MemberValidator from "@/mixins/validators/MemberValidator";
    import moment from "moment";
    import util from "@/mixins/util";
    import VuxAlert from "@/mixins/vux-alert"
    export default {
        name: "ParentalConsentModal",
        mixins: [VuxAlert, MemberValidator],
        components: {
        },
        props: {
            setData: {
                type: Function,
                default: () => {}
            },
            changeStep: {
                type: Function,
                default: () => {}
            },
            menteePhone: {
                default: '',
            },
            runRegist: {
                type: Function,
                default: () => {},
            },
            registerBtnDisabled: {
                default: false,
            }
        },
        data() {
            return {
                parentAccept: false,
                hasError: false,
                pickerOptions: {
                    disabledDate(time) {
                        // return time.getTime() > Date.now();
                        return moment(time).format('YYYY-MM-DD') > moment().add(-19, 'y').format('YYYY-MM-DD');
                    },
                },
                // parentBirth: moment().add(-19, 'y').format('YYYY-MM-DD'),

                parentBirth: moment('1980-01-01').format('YYYY-MM-DD'),
                sameParentPhone: false,
            }
        },
        beforeRouterEnter() {},
        created() {
            this.parentSetData('parentBirth', this.parentBirth);
            //EventBus.$on
        },
        mounted() {
            //EventBus.$on('')
        },
        beforeDestroy() {
        },
        destroyed() {},
        computed: {

        },
        methods:{
            parentSetData(type, value) {
                // this[type] = value;
                if(type === 'parentPhone') {
                    this.parentPhoneCertifyAuth = '';
                    this.sameParentPhone = false;
                }
                if(type === 'parentPhoneCertify') {
                    this.parentPhoneCertifyFail = false;
                }
                this.setData(type, value)
            },
            backEvent() {
                this.$modal.hide('parental-consent-modal')
            },
            validateCheck() {
                let validatorCheck = ['parentName', 'parentBirth', 'parentPhone', 'parentPhoneCertify', 'parentPhoneCertifyAuth'];
                this.$validate(validatorCheck).then(success => {
                    if(!success) {
                        this.hasError = true;
                        return false;
                    }
                    if(!this.parentAccept) {
                        this.hasError = true;
                        return false;
                    }
                    this.checkPaerntPhoneCertify(this.nextRegist)
                })
            },
            scrollDisable(){
                $('html, body').addClass('hidden');
            },
            scrollAble(){
                $('html, body').removeClass('hidden');

            },
            checkSamePhone() {
                if(this.menteePhone === this.parentPhone) {
                    //this.errorAlert('');
                    this.sameParentPhone = true;
                    return;
                }
                this.sendPaerntPhoneCertify();
            },
            nextRegist() {
                this.$modal.hide('parental-consent-modal')
                this.runRegist();
            },
        },
        watch: {

        },
    }
</script>

<style lang="scss">

</style>
