<template>
    <component :is="component" :boardData="boardData" :categorys="categorys" v-if="component"  />
</template>
<script>
    export default {
        name: 'BoardDetailLink',
        props: {
            detailLayout: {

            },
            boardData: {
                type: Object,
                default: () => {return {}},
            },
            categorys: {
                type: Array,
                default: () => {return []},
            },
        },
        data() {
            return {
                component: null,
            }
        },
        computed: {
            loader() {
                if (!this.detailLayout) {
                    return null
                }
                return () => import(`@/template/board/detail/${this.detailLayout}`)
            },
        },
        mounted() {
            this.loader()
                .then(() => {
                    this.component = () => this.loader()
                })
                .catch(() => {
                    this.component = () => import('@/template/board/detail/BoardDetailDefaultLayout')
                })
        },
    }
</script>