<template>
    <modal name="comment-modify-modal" class="comment-modify-modal vh100"
           :width="'100%'"
           :height="'100%'"
           :transition="`modal-bottom`"
    >
        <div>
            <page-header :BackEvent="BackEvent"></page-header>
            <ion-content>
                <comment-write-component :boardType="'letterCommentModify'" :commentData="commentData"></comment-write-component>
            </ion-content>
        </div>
    </modal>
</template>

<script>
    // @ is an alias to /src
    import CommentWriteComponent from "@/components/board/comment/CommentWriteComponent";

    export default {
        name: 'CommentModifyModal',
        mixins: [],
        components: {
            CommentWriteComponent
        },
        props: {
            commentData: { default: () => {} }
        },
        data() {
            return{

            }
        },
        beforeRouterEnter() {},
        created() {

        },
        mounted() {

        },
        beforeDestroy() {
        },
        destroyed() {},
        computed: {
        },
        methods:{
            BackEvent() {
                this.$modal.hide('comment-modify-modal')
            },
        },
        watch: {

        },
    }
</script>
