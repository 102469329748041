const termsConfig = {
    privacy: {
        termsKey: 'cs_privacy',
    },
    stipulation: {
        termsKey: 'cs_stipulation'
    },
    pay: {
        termsKey: 'cs_refund'
    },
    leave: {
        termsKey: 'cs_leave'
    },


}

export default {
    methods: {
        returnTermsConfig(type) {
            return termsConfig[type];
        }
    }
}