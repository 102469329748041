<template>
    <component :is="component" :memberData="memberData" :total="total" :categorys="categorys" v-if="component"  />
</template>
<script>
    export default {
        name: 'MemberListLink',
        mixins: [],
        components: {
        },
        props: {
            listLayout: {

            },
            memberData: {
                type: Array,
                default: () => {return []}
            },
            total: {
                type: Number,
                default: 0,
            },
            categorys: {
                type: Array,
                default: () => {return []},
            },
        },
        beforeRouterEnter() {},
        created() {
        },
        mounted() {
            this.loader()
                .then(() => {
                    this.component = () => this.loader()
                })
                .catch(() => {
                    this.component = () => import('@/template/member/list/MemberListDefaultLayout')
                })
        },
        data() {
            return {
                component: null,
            }
        },

        beforeDestroy() {
        },
        destroyed() {},
        computed: {
            loader() {
                if (!this.listLayout) {
                    return null
                }
                return () => import(`@/template/member/list/${this.listLayout}`)
            },
        },
        methods:{

        },
        watch: {

        },
    }
</script>