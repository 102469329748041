<template>
    <div class="banner_wrap">
<!--        <div class="banner_img" :style="`background-image: url(${returnBannerImage()})`"></div>-->
        <div class="banner_content" v-html="banner.p_content"></div>
    </div>
</template>
<script>
    import EventBus from "../../utils/event-bus";
    import VuxAlert from "@/mixins/vux-alert";
    import imageOption from "@/mixins/imageOption";

    export default {
        name: "BannerDetailComponent",
        mixins: [VuxAlert, imageOption],
        components: {},
        props: {
            bannerKey: {},
        },
        data() {
            return {
                banner: {},
            }
        },
        beforeRouterEnter() {
        },
        created() {
        },
        mounted() {
            this.getBanner();
        },
        beforeDestroy() {
        },
        destroyed() {
        },
        computed: {},
        methods: {
            getBanner() {
                console.log('bannerkey = ',this.bannerKey)
                let params = {
                    fi: 1,
                    ip: 0,
                    id: this.bannerKey,
                    isdi: 1,
                }
                this.$api.$banner.getBanner(params).then(res => res.Data.Result).then(res => {
                    if (res.Info.type == 1) {
                        this.banner = res.List[0];
                    }
                })
            },
            returnBannerImage() {
                let banner = this.banner
                return banner.hasOwnProperty('File') && banner.File.hasOwnProperty('popupMobaile') ? `${banner.File.popupMobaile.org_url}?${this.setImageOptions(236, 236, 'png')}` : ''
            },
        },
        watch: {},
    }
</script>

<style scoped>

</style>