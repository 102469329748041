<template>
    <div>
        <!--<div class="form">
            <div class="mo_title">탈퇴사유</div>
            <div class="m_sub_title">해당되는 사유를 선택해주세요</div>
            <div class="checkbox ">
                <div >
                    <span>서비스 속도가 느리다.</span>
                    <input type="checkbox" mode="ios" slot="start"></input>
                </div>
                <div >
                    <span>사용이 불편하다.</span>
                    <input type="checkbox" mode="ios" slot="start"></input>
                </div>
                <div >
                    <span>사용할 시간이 없다.</span>
                    <input type="checkbox" mode="ios" slot="start"></input>
                </div>
                <div >
                    <span>기타(직접입력)</span>
                    <input type="checkbox" mode="ios" slot="start"></input>
                </div>
                <div class="mt5">
                    <input type="textarea" rows="3" class="rg_textarea" placeholder="기타 사유를 입력해주세요 (100자 이내)" maxLength="100"></input>
                </div>
            </div>
        </div>
        <div class="m_passwd mt10 pt10 borderT">
            <div class="mo_title">비밀번호</div>
            <div class="m_sub_title pb10">본인 확인을 위해 현재 비밀번호를 입력하세요 </div>
            <input value="password" class="mb7" maxlength=16 type="password"></input>

-->
        <div class="form">
            <div class="title-wrap">
                <div class="title">탈퇴 사유</div>
                <div class="sub-title">해당되는 사유를 선택해주세요</div>
            </div>


            <div class="value-wrap">
                <div class="value">
                    <div class="checks" v-for="quitCate in quitCategories" @click="clickCheckbox=true"
                         :key="`quitCate${quitCate.moc_idx}`">
                        <input type="checkbox"
                               :id="`value${quitCate.moc_idx}`"
                               :checked="quitCate.moc_idx === quitList[0]"
                               @change="selectQuitCate(quitCate,$event.target.checked)"
                        >
                        </input>
                        <label :for="`value${quitCate.moc_idx}`">{{quitCate.moc_category}}</label>
                    </div>
                    <div class="wrong_form_msg_quit" v-if="validation.hasError('quitList')"
                         v-html="validation.firstError('quitList')"></div>
                    <div class="value" v-show="hasInput">
                    <textarea class="extra" placeholder="기타 사유를 입력해주세요 (100자 이내)"
                              rows="3"
                              maxLength="100"
                              @input="setData('quitReason',$event.target.value)"
                    ></textarea>
                        <div class="wrong_form_msg_quit mt5" v-if="validation.hasError('quitReason')"
                             v-html="validation.firstError('quitReason')"></div>

                    </div>
                </div>
                <div class="title-wrap">
                    <div class="title">비밀번호</div>
                    <div class="sub-title">본인 확인을 위해 현재 비밀번호를 입력하세요</div>
                </div>
                <input type="password" placeholder="현재 비밀번호 입력"
                       @input="setData('password',$event.target.value)"></input>
                <div class="wrong_form_msg_quit mt5" v-if="validation.hasError('password')"
                     v-html="validation.firstError('password')"></div>
                <div class="wrong_form_msg_quit mt5" v-else-if="this.errMsg && this.hasErr">{{this.errMsg}}</div>

            </div>
        </div>
    </div>
</template>
<script>
    import {mapGetters, mapState} from "vuex";
    import EventBus from "@/utils/event-bus";
    import MemberValidator from "@/mixins/validators/MemberValidator";
    import VuxAlert from "@/mixins/vux-alert"
    import {logout} from "../../mixins/Auth";

    export default {
        name: 'QuitComponent',
        mixins: [MemberValidator, VuxAlert],
        components: {},
        props: {},
        data() {
            return {
                quitCategories: [],
                checkReason: '',
                checkedCate: false,
                errMsg: '',
                hasErr: '',
                hasInput: false,
                clickCheckbox: true,

            }
        },
        beforeRouterEnter() {
        },
        beforeCreate() {
        },
        created() {

        },
        beforeMount() {
        },
        mounted() {
            this.getQuitCategory()
            EventBus.$on('quitMemberFunc', this.quitMember)

        },
        beforeUpdate() {
        },
        updated() {
        },
        beforeRouterLeave() {
        },
        beforeDestroy() {
            EventBus.$off('quitMemberFunc')
        },
        destroyed() {
        },
        computed: {
            ...mapState({
                UserInfo: state => state.userInfo.UserInfo,
            })
        },
        methods: {
            selectQuitCate(value, check) {
                if (this.clickCheckbox === false) {
                    return;
                }
                if (check) {
                    this.quitList = [value.moc_idx];
                } else {
                    this.quitList = [];
                    this.hasInput = false;

                }
                this.setData('quitList', this.quitList)
                if (value.moc_require_input === 1 && check) {
                    this.hasInput = true;
                } else if (value.moc_require_input != 1) {
                    this.hasInput = false;

                }


                this.clickCheckbox = false;
            },
            getQuitCategory() {
                let params = {
                    moc_type: 'normal',
                }
                try {
                    this.$api.$member.getQuitCategory(params).then(res => res.Data.Result).then(res => {
                        if (res.Info.type === 1) {
                            this.quitCategories = res.List;
                        }

                    })
                } catch (e) {
                    console.log(e)
                }
            },
            setData(key, value) {
                this[key] = value;
                this.hasErr = false

            },

            quitMember() {
                let validatorCheck = ['password', 'quitList']
                if (this.hasInput === true) {
                    validatorCheck.push('quitReason')
                }
                this.$validate(validatorCheck).then(success => {
                    if (!success) {
                        return false;
                    }
                    let reasonList = [];
                    reasonList.push(this.quitReason);

                    let params = {
                        mb_no: this.UserInfo.mb_no,
                        moc_idx: this.quitList,
                        mo_reason: reasonList,
                        mb_password: this.password,

                    }
                    try {
                        this.$api.$member.deleteMember(params).then(res => res.Data.Result).then(res => {
                            let type = this.quitReturnType(res.Info.type);
                            if (type != true) {
                                this.hasErr = true
                                this.errMsg = type
                                return false;
                            }
                            this.createAlert({
                                title: '',
                                content: '이용해주셔서 감사합니다',
                                btnText: '확인',
                                hide: () => {
                                    logout()
                                }
                            })
                        })
                    } catch (e) {

                    }
                })
            },
            quitReturnType(type) {
                switch (type) {
                    case 1:
                        return true;
                    case -2:
                        return '회원이 아닙니다';
                    case -3:
                        return '회원 정보가 없습니다';
                    case -4:
                        return '현재 비밀번호가 일치하지 않습니다';
                    case -5:
                        return '탈퇴 사유를 선해 주세요';
                    case -6:
                        return '탈퇴 사유를 입력해 주세요';
                    case -7:
                        return '일반회원이 다른 회원을 탈퇴시킬 수 없습니다';
                    default:
                        return '탈퇴에 실패했습니다. 잠시후 다시 시도해주세요.'
                }
            },

        }
        ,
        watch: {}
        ,
    }
</script>