<template>
    <component :is="component" v-if="component"  />
</template>
<script>
    export default {
        name: 'MemberUpdateLink',
        props: {
            updateLayout: {

            },
        },
        data() {
            return {
                component: null,
            }
        },
        computed: {
            loader() {
                if (!this.updateLayout) {
                    return null
                }
                return () => import(`@/template/member/update/${this.updateLayout}`)
            },
        },
        mounted() {
            this.loader()
                .then(() => {
                    this.component = () => this.loader()
                })
                .catch(() => {
                    this.component = () => import('@/template/member/update/MemberUpdateDefaultLayout');
                })
        },
    }
</script>