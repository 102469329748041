<template>
    <div class="modal-wrap">
        <div class="title">전화번호 수정</div>
        <div class="form">
            <div class="form-data">
                <div class="title">전화번호</div>
                <div class="value">
                    <div class="check">
                        <input type="tel" placeholder="’-’ 제외, 숫자만 입력" maxLength="11"
                               @input="setData('phone',$event.target.value)"></input>
                        <button class="send" @click="sendPhoneCertify">인증번호 전송</button>
                    </div>
                    <!--                    <div class="form-caption">중복된 전화번호입니다</div>-->
                    <div class="form-caption" v-if="validation.hasError('phone')"
                         v-html="validation.firstError('phone')"></div>
                    <div class="form-caption" v-else-if="phoneOverlap">중복된 전화번호 입니다.</div>
                </div>
            </div>
            <div class="form-data" v-if="phoneCertifyAuth != ''">
                <div class="title">인증번호</div>
                <div class="value">
                    <input type="text" placeholder="인증번호 입력"
                           @input="setData('phoneCertify',$event.target.value)"></input>
                    <!--                    <div class="form-caption">인증번호가 일치하지 않습니다</div>-->
                    <div class="form-caption"
                         v-if="validation.hasError('phoneCertify') || validation.hasError('phoneCertifyAuth')">모두 입력해
                        주세요
                    </div>
                    <div class="form-caption" v-else-if="phoneCertifyFail ">인증번호가 일치하지 않습니다</div>

                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import MemberValidator from "@/mixins/validators/MemberValidator";
    import EventBus from "@/utils/event-bus";
    import VuxAlert from "@/mixins/vux-alert";
    import {mapState} from "vuex";

    export default {
        name: "MemberUpdatePhoneComponent",
        mixins: [MemberValidator, VuxAlert,],
        components: {},
        props: {
        },
        data() {
            return {}
        },
        beforeRouterEnter() {
        },
        created() {

        },
        mounted() {
            EventBus.$on('updatePhoneNumber', this.updatePhoneNumber)

        },
        beforeDestroy() {
            EventBus.$off('updatePhoneNumber')

        },
        destroyed() {
        },
        computed: {
            ...mapState({
                UserInfo: state => state.userInfo.UserInfo,
            })
        },
        methods: {

            setData(key, value) {
                this[key] = value;
                if (key === 'phone') {
                    this.phoneCertifyAuth = '';
                    this.phoneOverlap = false

                }
                if (key === 'phoneCertify') {
                    this.phoneCertifyFail = false;
                }
            },
            updatePhoneNumber() {
                this.checkPhoneCertify(this.nextFunc)

            },
            nextFunc() {
                let updateParams = {
                    mb_hp: this.phone
                }
                console.log(updateParams.mb_hp)
                this.$api.$member.updateMember(updateParams).then(res => res.Data.Result).then(res => {
                    this.$store.dispatch('userInfo/setUserInfo', {token: this.$localStorage.get('token')});
                    if (res.Info.type != -1) {
                        this.createAlert({
                            title: '',
                            content: '전화번호가 변경되었습니다',
                            btnText: '확인',
                            hide: () => {
                                this.$modal.hide('update-phone-modal')
                            }
                        })
                    }
                })

            },
        },
        watch: {},
    }
</script>

<style scoped>

</style>