<template>
    <div>
        <board-write-link v-for="writeLayout in writeLayouts" :key="`boardComp${writeLayout}`" :writeLayout="writeLayout" :boardData="boardData"></board-write-link>
    </div>

</template>
<script>
    import util from "@/mixins/util";
    import BoardWriteLink from "@/components/board/BoardWriteLink";
    import boardTemplateUtil from "@/components/board/boardTemplateUtil"
    import boardFilterUtil from "@/components/board/boardFilterUtil"
    import EventBus from "@/utils/event-bus";
    import VuxAlert from '@/mixins/vux-alert';
    import {mapGetters, mapState} from "vuex";
    import BoardValidator from "@/mixins/validators/BoardValidator";
    import elFileMixins from "@/mixins/elFileMixins";

    export default {
        name: 'BoardWriteComponent',
        mixins: [ VuxAlert, boardTemplateUtil, boardFilterUtil, BoardValidator, elFileMixins],
        components: {
            BoardWriteLink
        },
        provide() {
            return {
                boardWriteSetData: this.setData,
                boardParsingDetailData: this.parsingDetailData,
            }
        },
        props: {
            boardType: {
                type: String,
                default: '',
            },
            boardKey: {
                default: 0
            },
        },
        data() {
            return{
                boardData: {},

                writeLayouts: [],
                writeFunc: '',
                writeApiParamSet: {},
                parsingData: {},
                sendFileType: null,
                directUrl: '',
                directType: '',
                resultAlertTxt: '',
                getFunc: '',
                detailApiParamSet: {},
                keyName: '',
            }
        },
        beforeRouterEnter() {},
        beforeCreate() {},
        created() {
            this.initFilterConfig();
            if(this.boardKey === 0) {
                this.initTemplateConfig();
            }else{
                this.getBoard();
            }
            EventBus.$on('writeBoard', this.writeBoard);
        },
        beforeMount() {},
        mounted() {
        },
        beforeUpdate() {},
        updated() {},
        beforeRouterLeave() {},
        beforeDestroy() {
            EventBus.$off('writeBoard');
        },
        destroyed() {},
        computed: {

        },
        methods: {
            setData(key, value) {
                this[key] = value;
            },
            initTemplateConfig() {
                let boardTemplateConfig = this.returnBoardTemplateConfig(this.boardType);
                this.writeLayouts = boardTemplateConfig.writeLayouts;
            },
            initFilterConfig() {
                let boardFilterConfig = this.returnBoardFilterConfig(this.boardType);
                this.writeFunc = boardFilterConfig.writeFunc;
                this.writeApiParamSet = boardFilterConfig.writeApiParamSet;
                this.parsingData = boardFilterConfig.parsingData;
                this.sendFileType = boardFilterConfig.sendFileType;
                this.directUrl = boardFilterConfig.directUrl;
                this.directType = boardFilterConfig.directType;
                this.resultAlertTxt = boardFilterConfig.resultAlertTxt;
                this.getFunc = boardFilterConfig.getFunc;
                this.detailApiParamSet = boardFilterConfig.detailApiParamSet;
                this.keyName = boardFilterConfig.keyName;
            },
            getBoard() {
                let params = JSON.parse(JSON.stringify(this.detailApiParamSet))
                params.id = this.boardKey;
                this.$api.$board[this.getFunc](params).then(res => res.Data.Result).then(res => {
                    if(res.Info.type != 1) {
                        this.createAlert({
                            content: '게시글을 불러올 수 없습니다.',
                            btnText: '확인',
                            hide: () => {
                                this.$root.$children[0].hasBack = true;
                                this.$router.go(-1);
                            }
                        })
                        return false;
                    }
                    this.boardData = res.List[0];
                    this.initTemplateConfig();
                })
            },
            parsingDetailData(dataKey) {
                let boardKey = this.parsingData[dataKey];
                return this.boardData[boardKey];
            },
            writeBoard() {
                let dataKeys = Object.keys(this.parsingData);
                this.$validate(dataKeys).then((success) => {
                    if(!success) {
                        this.errorAlert('필수데이터를 확인해주세요.');
                        return false;
                    }
                    // let params = this.createApiParamSet;
                    let params = JSON.parse(JSON.stringify(this.writeApiParamSet))
                    dataKeys.forEach(dataKey => {
                        params[this.parsingData[dataKey]] = this[dataKey];
                    })
                    if(this.boardKey != 0) {
                        params[this.keyName] = this.boardKey;
                    }
                    try {
                        this.$api.$board[this.writeFunc](params).then(res => res.Data.Result).then(res => {

                            let type = this.returnBoardWriteType(res.Info.type);
                            if(type != true) {
                                this.errorAlert(type);
                                return false;
                            }
                            if(!util.isEmpty(this.deleteFiles)) {

                                this.removeFiles(this.deleteFiles)
                            }
                            if(!util.isEmpty(this.sendFileType) && this.files.filter(file => file.hasOwnProperty('raw')).length > 0){
                                this.uploadFiles(this.files, this.sendFileType, res.List).then(success => {
                                    this.afterFunc(res.List);
                                });
                            }else{
                                this.afterFunc(res.List);
                            }
                        })
                    }catch (e) {
                        console.log(e)
                    }
                })
            },
            returnBoardWriteType(type) {
                switch (type) {
                    case 1:
                        return true;
                    case -2:
                        return '권한이 없습니다.';
                    case -103:
                        return '삭제된 게시글입니다.';
                    default:
                        return '등록에 실패하였습니다. 잠시 후 다시 시도해주세요.';
                }
            },
            afterFunc(key) {
                if(this.boardKey != 0) {
                    EventBus.$emit('boardListUpdateAfterFunc', this.keyName, Number(this.boardKey));
                }else{
                    EventBus.$emit('boardListAddAfterFunc');
                }
                let detailKey = key;
                this.createAlert({
                    title: '',
                    content: this.resultAlertTxt,
                    btnText: '확인',
                    hide: () => {
                        if(this.directType === 'back') {
                            this.$router.back();
                            return false;
                        }
                        if(this.directUrl === '') {
                            return false;
                        }
                        let url = this.directUrl;
                        if(this.directType === 'detail') {
                            url = url+detailKey;
                        }
                        this.$router.push(url);
                    }
                })
            },
        },
        watch: {},
    }
</script>