<template>
    <modal name="image-detail-modal" class="comment-modify-modal image-detail-modal vh100"
           :width="'80%'"
           :height="'80%'"
           @before-open="scrollDisable"
           @before-close="scrollAble"
    >
        <div class="image-view-wrap">
            <div class="image-view">
                <img :src="imageUrl" alt="">
            </div>
            <button class="close-btn" @click="BackEvent">
                <i class="el-icon-close"></i>
            </button>
        </div>
    </modal>
</template>

<script>
    // @ is an alias to /src

    export default {
        name: 'ImageDetailModal',
        mixins: [],
        components: {
        },
        props: {
            imageUrl: {default: ''},
        },
        data() {
            return{

            }
        },
        beforeRouterEnter() {},
        created() {

        },
        mounted() {
        },
        beforeDestroy() {
        },
        destroyed() {},
        computed: {
        },
        methods:{
            BackEvent() {
                this.$modal.hide('image-detail-modal')
            },
            scrollDisable(){
                $('html, body').addClass('hidden');
            },
            scrollAble(){
                $('html, body').removeClass('hidden');

            }
        },
        watch: {

        },
    }
</script>
