const configBoardFilter = {
    letter: {
        getFunc: 'getLetter',
        paginationType: 'pagination',
        listApiParamSet: {
            ip: 1,
            wr_type: 'post',
            opf: 1,
        },
        pageRow: 15,
    },
    letterListLike: {
        likeFunc: 'likeLetter',
        keyName: 'wr_id'
    },
    letterDetail: {
        getFunc: 'getLetter',
        detailApiParamSet: {
            ip: 0,
            opd: 1,
            wr_type: 'post',
        },
        detailType: 'page',
        modalName: '',
        deleteFunc: '',
        deleteApiParamSet: {},
        blindFunc: '',
        keyName: 'wr_id',
    },
    letterLike: {
        likeFunc: 'likeLetter',
        keyName: 'wr_id'
    },
    letterComment: {
        getFunc: 'getLetter',
        paginationType: 'pagination',
        listApiParamSet: {
            ip: 1,
            opd: 1,
            wr_type: 'comment',
        },
        pageRow: 10,
        writeFunc: 'createLetter',
        writeApiParamSet: {
            wr_type: 'comment',
        },
        updateFunc: 'updateLetter',
        updateApiParamSet: {
            wr_type: 'comment',
        },
        deleteFunc: 'deleteLetter',
        deleteApiParamSet: {
            wr_type: 'comment',
        },
        blindFunc: 'blindLetter',
        keyName: 'wr_id',
    },
    letterCommentModify: {
        writeFunc: 'updateLetter',
        writeApiParamSet: {
            wr_type: 'comment',
        },
        keyName: 'wr_id',
        pageType: 'modal',
        modalName: 'comment-modify-modal'
    },
    letterSearch: {
        getFunc: 'getLetter',
        paginationType: 'pagination',
        listApiParamSet: {
            ip: 1,
            wr_type: 'post',
        },
        filterData: {},
        pageRow: 10,
    },
    myCommentLetter: {
        getFunc: 'getMyCommentLetter',
        paginationType: 'pagination',
        listApiParamSet: {
            ip: 1,
            sp: 1,

        },
        pageRow: 10,
    },
    myLikeLetter: {
        getFunc: 'getMyLikeLetter',
        paginationType: 'pagination',
        listApiParamSet: {
            ip: 1,

        },
    },
    commu: {
        getFunc: 'getCommu',
        paginationType: 'pagination',
        listApiParamSet: {
            ip: 1,
            wr_type: 'post',
            opf: 1,
            opw: 1,
        },
        pageRow: 10,
    },
    commuListLike: {
        likeFunc: 'likeCommu',
        keyName: 'wr_id'
    },
    commuHome: {
        getFunc: 'getCommu',
        paginationType: 'pagination',
        listApiParamSet: {
            ip: 1,
            wr_type: 'post',
            opf: 1,
            opw: 1,
        },
        pageRow: 10,
    },
    commuDetail: {
        getFunc: 'getCommu',
        detailApiParamSet: {
            ip: 0,
            opd: 1,
            opw: 1,
            wr_type: 'post',
        },
        deleteFunc: 'deleteCommu',
        deleteApiParamSet: {
            wr_type: 'post'
        },
        detailType: 'page',
        modalName: '',
        keyName: 'wr_id',
        blindFunc: 'blindCommu',
    },
    commuComment: {
        getFunc: 'getCommu',
        paginationType: 'pagination',
        listApiParamSet: {
            ip: 1,
            opd: 1,
            wr_type: 'comment',
        },
        pageRow: 10,
        writeFunc: 'createCommu',
        writeApiParamSet: {
            wr_type: 'comment',
        },
        updateFunc: 'updateCommu',
        updateApiParamSet: {
            wr_type: 'comment',
        },
        deleteFunc: 'deleteCommu',
        deleteApiParamSet: {
            wr_type: 'comment',
        },
        blindFunc: 'blindCommu',
        keyName: 'wr_id',
    },
    commuCommentModify: {
        writeFunc: 'updateCommu',
        writeApiParamSet: {
            wr_type: 'comment',
        },
        keyName: 'wr_id',
        pageType: 'modal',
        modalName: 'comment-modify-modal'
    },
    commuWrite: {
        writeFunc: 'createCommu',
        writeApiParamSet: {
            wr_type: 'post'
        },
        parsingData: {
            'title': 'wr_subject',
            'content': 'wr_content',
            'mentor': 'mentors',
            'type': 'b_type'
        },
        sendFileType: 'free',
        directUrl: '/commu/',
        directType: 'back',
        resultAlertTxt: '소통 게시글이 작성되었습니다.',
        keyName: 'wr_id',
    },
    commuUpdate: {
        writeFunc: 'updateCommu',
        writeApiParamSet: {
            wr_type: 'post'
        },
        parsingData: {
            'title': 'wr_subject',
            'content': 'wr_content',
            'mentor': 'mentors',
            'secret': 'secret'
        },
        sendFileType: 'free',
        directUrl: '/detail/',
        directType: 'back',
        resultAlertTxt: '소통 게시글이 수정되었습니다.',
        getFunc: 'getCommu',
        detailApiParamSet: {
            ip: 0,
            opd: 1,
            opw: 1,
            wr_type: 'post',
        },
        keyName: 'wr_id',
    },
    myCommentCommu: {
        getFunc: 'getMyCommentCommu',
        paginationType: 'pagination',
        listApiParamSet: {
            ip: 1,
            sp: 1,
            opd: 1,
            opw: 1,

        },
        pageRow: 10,
    },
    myLikeCommu: {
        getFunc: 'getMyLikeCommu',
        paginationType: 'pagination',
        listApiParamSet: {
            ip: 1,
            opd: 1,
            opw: 1,
        },
        pageRow: 10,
    },
    myMemteeCommu: {
        getFunc: 'getCommu',
        paginationType: 'pagination',
        listApiParamSet: {
            ip: 1,
            wr_type: 'post',
            opf: 1,
            opw: 1,
            isM: 1,
        },
        pageRow: 10,
    },
    notice: {
        getFunc: 'getNotice',
        paginationType: 'pagination',
        listApiParamSet: {
            ip: 1,
            wr_type: 'post',
        },
        pageRow: 10,
    },
    noticeDetail: {
        getFunc: 'getNotice',
        detailApiParamSet: {
            ip: 0,
            wr_type: 'post',
            opd: 1,
        },
        deleteFunc: '',
        deleteApiParamSet: {},
        detailType: 'modal',
        modalName: 'notice-detail-modal',
        keyName: 'wr_id',
        blindFunc: '',
    },
    faq: {
        getFunc: 'getFaq',
        paginationType: 'pagination',
        listApiParamSet: {
            ip: 1,
            sp: 1,
        },
        pageRow: 10,
    },
    mentor:{
        getFunc: 'getMentorCommu',
        paginationType: 'lazyload',
        listApiParamSet: {
            ip: 1,
            wr_type: 'post',
            opf: 1,//파일 보이는 여부
            opw: 1, //작성자
        },
        pageRow: 10,
    },
    mentorWrite: {
        writeFunc: 'createMentorCommu',
        writeApiParamSet: {
            wr_type: 'post'
        },
        parsingData: {
            'title': 'wr_subject',
            'content': 'wr_content',
        },
        sendFileType: 'mentor',
        directUrl: '/mentor',
        directType: 'mentor',
        resultAlertTxt: '멘토 소통 게시글이 작성되었습니다.',
        keyName: 'wr_id',
    },
    mentorUpdate: {
        writeFunc: 'updateMentorCommu',
        writeApiParamSet: {
            wr_type: 'post'
        },
        parsingData: {
            'title': 'wr_subject',
            'content': 'wr_content',
        },
        sendFileType: 'mentor',
        directUrl: '/mentor',
        directType: 'back',
        resultAlertTxt: '멘토 소통 게시글이 수정되었습니다.',
        getFunc: 'getMentorCommu',
        detailApiParamSet: {
            ip: 0,
            opd: 1,
            wr_type: 'post',
            opw: 1,
        },
        keyName: 'wr_id',
    },
    mentorDetail: {
        getFunc: 'getMentorCommu',
        detailApiParamSet: {
            ip: 0,
            opd: 1,
            opw: 1,
            wr_type: 'post',
        },
        deleteFunc: 'deleteMentorCommu',
        deleteApiParamSet: {
            wr_type: 'post'
        },
        detailType: 'page',
        modalName: '',
        keyName: 'wr_id',
        blindFunc: 'blindMentorCommu',
    },
    mentorCommentModify:{
        writeFunc: 'updateMentorCommu',
        writeApiParamSet: {
            wr_type: 'comment',
        },
        keyName: 'wr_id',
        pageType: 'modal',
        modalName: 'comment-modify-modal'
    },
    myPostMentor:{
        getFunc: 'getMyPostMentorCommu',
        paginationType: 'lazyload',
        listApiParamSet: {
            ip: 1,
            wr_type: 'post',
            opf: 1,
            opw: 1,
            isM: 1,
        },
        pageRow: 10,
    },
    myCommentMentor:{
        getFunc: 'getMyCommentMentorCommu',
        paginationType: 'lazyload',
        listApiParamSet: {
            ip: 1,
            /*  sp: 1,
              opd: 1,
              opw: 1,*/

        },
        pageRow: 10,
    },
    myLikeMentor:{
        getFunc: 'getMyLikeMentorCommu',
        paginationType: 'lazyload',
        listApiParamSet: {
            ip: 1,
            opd: 1,
            opw: 1,
        },
        pageRow: 10,
    },
    mentorListLike: {
        likeFunc: 'likeMentorCommu',
        keyName: 'wr_id'
    },
    mentorLike: {
        likeFunc: 'likeMentorCommu',
        keyName: 'wr_id'
    },
    mentorComment: {
        getFunc: 'getMentorCommu',
        paginationType: 'lazyload',
        listApiParamSet: {
            ip: 1,
            opd: 1,
            wr_type: 'comment',
        },
        pageRow: 10,
        writeFunc: 'createMentorCommu',
        writeApiParamSet: {
            wr_type: 'comment',
        },
        updateFunc: 'updateMentorCommu',
        updateApiParamSet: {
            wr_type: 'comment',
        },
        deleteFunc: 'deleteMentorCommu',
        deleteApiParamSet: {
            wr_type: 'comment',
        },
        blindFunc: 'blindMentorCommu',
        keyName: 'wr_id',
    },
    amidan: {
        getFunc: 'getAmidan',
        paginationType: 'pagination',
        listApiParamSet: {
            ip: 1,
            wr_type: 'post',
        },
        pageRow: 10,
    },
    amidanDetail: {
        getFunc: 'getAmidan',
        detailApiParamSet: {
            ip: 0,
            wr_type: 'post',
            opd: 1,
        },
        deleteFunc: '',
        deleteApiParamSet: {},
        detailType: 'modal',
        modalName: 'amidan-detail-modal',
        keyName: 'wr_id',
        blindFunc: '',
    },
}

export default {
    methods: {
        returnBoardFilterConfig(type) {
            return configBoardFilter[type];
        }
    },
    filterConfig: {
        ca: {
            type: 'match',
            classname: 'filterCategory',
        },
        status: {
            type: 'match',
            classname: 'filterStatus',
        },
        pr: {
            type: 'match',
            classname: 'filterPublic',
        },
        su: {
            type: 'query',
            classname: 'filterInput',
        },
        na: {
            type: 'query',
            classname: 'filterInput',
        },
        co: {
            type: 'query',
            classname: 'filterInput',
        },
        isS: {
            type: 'match',
            classname: 'filterSecret'
        },
        isM: {
            type: 'match',
            classname: 'filterType'
        },
        cid: {
            type: 'match',
            classname: 'filterCate'
        }
    }
}
