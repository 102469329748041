<template>
    <member-detail-link :detailLayout="detailLayout" :memberData="memberData"></member-detail-link>
</template>

<script>
    import memberUtil from "@/components/member/memberUtil"
    import EventBus from "@/utils/event-bus";
    import {mapGetters, mapState} from "vuex";
    import MemberDetailLink from "@/components/member/MemberDetailLink";

    export default {
        name: "MemberDetailComponent",
        mixins: [memberUtil, ],
        components: {
            MemberDetailLink
        },
        props: {
            memberType: {
                type: String,
            },
            memberKey: {}
        },
        data() {
            return{
                getFunc: null,
                getCategoryFunc: null,
                detailLayout: '',
                detailApiParamSet: {},
                filterData: {
                    categorys: [],
                    filter: {},
                },
                categorys: [],

                memberData: {},
            }
        },
        beforeRouterEnter() {},
        created() {
            this.initConfig();
            /*this.getCategory();*/
            this.getDetail();
        },
        mounted() {

        },
        beforeDestroy() {
        },
        destroyed() {},
        computed: {
        },
        methods:{
            initConfig() {
                let memberConfig = this.returnMemberConfig(this.memberType);
                this.detailLayout = memberConfig.detailLayout;
                this.getFunc = memberConfig.getFunc;
                this.detailApiParamSet = memberConfig.detailApiParamSet;

                /*let boardFilterConfig = this.returnBoardFilterConfig(this.boardType);
                this.getFunc = boardFilterConfig.getFunc;
                this.getCategoryFunc = boardFilterConfig.getCategoryFunc;
                this.detailApiParamSet = boardFilterConfig.detailApiParamSet;*/
            },
            getDetail() {
                let params = JSON.parse(JSON.stringify(this.detailApiParamSet))
                params.no = this.memberKey;
                this.$api.$member[this.getFunc](params).then(res => res.Data.Result).then(res => {
                    if(res.Info.type != 1) {
                        return false;
                    }
                    this.memberData = res.List[0];
                })
            },
            setData(key, value) {
                this[key] = value;
            },
        },
        watch: {

        },
    }
</script>

<style scoped>

</style>