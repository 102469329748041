<template>
    <div class="modal-wrap">
        <div class="title">비밀번호 수정</div>
        <div class="form">
            <div class="form-data">
                <div class="title">현재 비밀번호</div>
                <div class="value">
                    <input type="password" placeholder="비밀번호 입력" v-model="prePassword"
                           @input="setData('prePassword',$event.target.value)" maxLength="16"></input>
<!--                    <div class="form-caption">비밀번호가 일치하지 않습니다.</div>-->
                    <div class="form-caption" v-if="validation.hasError('prePassword') && this.hasErr "
                         v-html="validation.firstError('prePassword')"></div>
                    <div class="form-caption" v-else-if="this.errMsg && this.hasErr">{{this.errMsg}}</div>
                </div>
            </div>

            <div class="form-data">
                <div class="title">변경할 비밀번호</div>
                <div class="value">
                    <input type="password" placeholder="8~16자, 대소문자구분, 특수문자가능"
                           @input="setData('password',$event.target.value)"  maxLength="16"></input>
<!--                    <div class="form-caption">잘못된 형식입니다.</div>-->
                    <div class="form-caption" v-if="validation.hasError('password') && this.hasErr"
                         v-html="validation.firstError('password')"></div>
                </div>
            </div>
            <div class="form-data">
                <div class="title">비밀번호 확인</div>
                <div class="value">
                    <input type="password" placeholder="비밀번호 확인"
                           @input="setData('passwordChk',$event.target.value)"  maxLength="16"></input>
<!--                    <div class="form-caption">비밀번호가 일치하지 않습니다.</div>-->
                    <div class="form-caption" v-if="validation.hasError('passwordChk') && this.hasErr"
                         v-html="validation.firstError('passwordChk')"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import MemberValidator from "@/mixins/validators/MemberValidator";
    import EventBus from "../../utils/event-bus";
    import VuxAlert from "@/mixins/vux-alert";

    export default {
        name: "MemberUpdatePwComponent",
        mixins: [MemberValidator, VuxAlert],
        components: {},
        props: {},
        data() {
            return {
                prePassword: '',
                password: '',
                passwordChk: '',
                errMsg: '',
                hasErr: false,
                onlyCaption: false
            }
        },
        beforeRouterEnter() {
        },
        created() {

        },
        mounted() {
            EventBus.$on('updatePassword', this.updatePassword)
        },
        beforeDestroy() {
            EventBus.$off('updatePassword')
        },
        destroyed() {
        },
        computed: {},
        methods: {

            setData(key, value) {
                this[key] = value;
                this.hasErr = false

            },
            updatePassword() {
                this.$validate(['prePassword', 'password', 'passwordChk']).then(res => {
                    if (!res) {
                        this.hasErr = true
                        return false
                    }
                    let params = {
                        mb_password: this.prePassword,
                        new_password: this.password,
                        new_password_confirmation: this.passwordChk,
                    }
                    try {
                        this.$api.$member.updatePassword(params).then(res => res.Data.Result).then(res => {
                            let type = this.returnUpdatePasswordTypeAlertTxt(res.Info.type);
                            if (type === true) {
                                this.createAlert(
                                    {
                                        title: '',
                                        content: '비밀번호가 변경되었습니다',
                                        btnText: '확인',
                                        hide: () => {
                                            // EventBus.$emit('passwordLength',this.password.length)
                                            this.$modal.hide('update-pw-modal')
                                        }
                                    }
                                )
                            } else {
                                this.hasErr = true
                                this.errMsg = type
                                if (this.onlyCaption === false) {
                                    this.errorAlert(type)
                                }
                                return false
                            }


                        })
                    } catch (e) {

                    }
                })

            },
            returnUpdatePasswordTypeAlertTxt(type) {
                switch (type) {
                    case 1:
                        return true;
                    case -3:
                        this.onlyCaption = true
                        return '현재 비밀번호를 입력해주세요';
                    default:
                        this.onlyCaption = false
                        return '수정에 실패하였습니다.'
                }
            },
        },
        watch: {},
    }
</script>

<style scoped>

</style>