<template>
    <div>
        <board-filter-link :filterLayout="filterLayout" :filterData="filterSet.filter"></board-filter-link>
        <board-empty-link :emptyLayout="emptyLayout" v-if="List.length === 0"></board-empty-link>
        <board-list-link :listLayout="listLayout" :total="filterSet.page.total" :boardData="List" v-else></board-list-link>
        <pagination-link :paginationLayout="paginationLayout" :paginationData="filterSet.page" :listLength="List.length"></pagination-link>
    </div>
</template>

<script>
    // @ is an alias to /src
    import BoardListLink from "@/components/board/BoardListLink";
    import PaginationLink from "@/components/board/PaginationLink";
    import BoardFilterLink from "@/components/board/BoardFilterLink";
    import BoardEmptyLink from "@/components/board/BoardEmptyLink";
    import boardTemplateUtil from "@/components/board/boardTemplateUtil"
    import boardFilterUtil from "@/components/board/boardFilterUtil"
    import listMixins from "@/mixins/listMixins";
    import filterMixins from "@/mixins/filterMixins";
    import EventBus from "@/utils/event-bus";
    import util from "@/mixins/util";
    const {filterConfig} = boardFilterUtil;
    export default {
        name: 'BoardListComponent',
        mixins: [filterMixins, listMixins, boardTemplateUtil, boardFilterUtil],
        components: {
            BoardListLink,
            BoardEmptyLink,
            PaginationLink,
            BoardFilterLink
        },
        provide: function () {
            return {
                setPage: this.setPage,
                setFilter: this.setFilter,
                setData: this.setData,
                afterLikeFunc: null,
                BoardListHasFilter: this.hasFilter,
                resetFilter: this.resetFilter,
            }
        },
        props: {
            boardType: {},
        },
        data() {
            return{
                listLayout: '',
                emptyLayout: '',
                filterLayout: '',
                paginationLayout: '',

                categorys: [],
                getFunc: '',
                listApiParamSet: {},
                pageRow: 10,


                /*필터 classname*/
                filterCategory: '',
                filterStatus: '',
                filterPublic: '',
                filterInput: '',
                filterInputOption: '',
                filterSecret: '',
                filterType: '',
                filterCate: '',
            }
        },
        beforeRouterEnter() {},
        created() {
            this.setFilterConfig(filterConfig)
            this.setParserType('url', this.$route.fullPath);
            this.initConfig();
            EventBus.$on('boardListAfterFunc', this.listToggleLikeFunc);
            EventBus.$on('listCommentCountAdd', this.listCommentCountAdd);
            EventBus.$on('listCommentCountMinus', this.listCommentCountMinus);
            EventBus.$on('boardListAddAfterFunc', this.boardListAddAfterFunc);
            EventBus.$on('boardListDeleteAfterFunc', this.boardListDeleteAfterFunc);
            EventBus.$on('boardListUpdateAfterFunc', this.boardListUpdateAfterFunc);
        },
        mounted() {
            this.getList(true);
        },
        beforeDestroy() {
            EventBus.$off('boardListAfterFunc');
            EventBus.$off('listCommentCountAdd');
            EventBus.$off('listCommentCountMinus');
            EventBus.$off('boardListAddAfterFunc');
            EventBus.$off('boardListDeleteAfterFunc');
            EventBus.$off('boardListUpdateAfterFunc');
        },
        destroyed() {},
        computed: {
        },
        methods:{
            initConfig() {
                let boardTemplateConfig = this.returnBoardTemplateConfig(this.boardType);
                this.listLayout = boardTemplateConfig.listLayout;
                this.emptyLayout = boardTemplateConfig.emptyLayout;
                this.paginationLayout = boardTemplateConfig.paginationLayout;
                this.filterLayout = boardTemplateConfig.filterLayout;

                let boardFilterConfig = this.returnBoardFilterConfig(this.boardType);
                this.getFunc = boardFilterConfig.getFunc;
                this.paginationType = boardFilterConfig.paginationType;
                this.listApiParamSet = boardFilterConfig.listApiParamSet;
                boardFilterConfig.pageRow && boardFilterConfig.pageRow != this.filterSet.page.size ? this.setPage('size', boardFilterConfig.pageRow) : '';
                let listOptions = {
                    url : this.$api.$board[this.getFunc],
                    defaultParams : this.listApiParamSet,
                }
                this.initList('api', listOptions);
            },
            setData(key, value) {
                this[key] = value;
            },
            hasFilter() {
                return !util.isEmpty(this.filterSet.filter);
            },
            listToggleLikeFunc(keyName, keyValue) {
                let boardIndex = this.List.findIndex(board => board[keyName] === keyValue);
                if(boardIndex > -1) {
                    let boardObj = this.List[boardIndex];
                    if( !boardObj.hasOwnProperty('is_like') || !boardObj.hasOwnProperty('wr_good')) {
                        return;
                    }
                    if(boardObj.is_like) {
                        boardObj.is_like = false;
                        boardObj.wr_good = boardObj.wr_good - 1
                    }else{
                        boardObj.is_like = true;
                        boardObj.wr_good = boardObj.wr_good + 1
                    }
                }
            },
            listCommentCountAdd(keyName, keyValue) {
                let boardIndex = this.List.findIndex(board => board[keyName] === keyValue);
                if(boardIndex > -1) {
                    let boardObj = this.List[boardIndex];
                    if( !boardObj.hasOwnProperty('CommentCount')) {
                        return;
                    }
                    boardObj.CommentCount = boardObj.CommentCount + 1;
                }
            },
            listCommentCountMinus(keyName, keyValue) {
                let boardIndex = this.List.findIndex(board => board[keyName] === keyValue);
                if(boardIndex > -1) {
                    let boardObj = this.List[boardIndex];
                    if( !boardObj.hasOwnProperty('CommentCount')) {
                        return;
                    }
                    boardObj.CommentCount = boardObj.CommentCount - 1;
                }
            },
            boardListAddAfterFunc() {
                let params = JSON.parse(JSON.stringify(this.listApiParamSet));
                params.page = 1;
                params.sp = 1;
                this.$api.$board[this.getFunc](params).then(res => res.Data.Result).then(res => {
                    if(res.Info.type === 1 && res.List.length > 0) {
                        this.List.unshift(res.List[0]);
                    }
                })
                if(this.paginationType === 'lazyload' && (this.List.length < this.filterSet.page.total)) {
                    this.List.splice(this.List.length - 1, 1);
                }
            },

            boardListDeleteAfterFunc(key, value) {
                let boardIndex = this.List.findIndex(board => board[key] === value);
                if(boardIndex < 0) {
                    return false;
                }
                this.List.splice(boardIndex, 1);
                this.filterSet.page.total = this.filterSet.page.total - 1;
                if(this.paginationType === 'lazyload' && (this.List.length < this.filterSet.page.total)) {
                    let params = JSON.parse(JSON.stringify(this.listApiParamSet));
                    params.page = this.filterSet.page.page * this.pageRow + 1;
                    params.sp = 1;
                    this.$api.$board[this.getFunc](params).then(res => res.Data.Result).then(res => {
                        if(res.Info.type === 1 && res.List.length > 0) {
                            this.List.push(res.List[0]);
                        }
                    })
                }
            },

            boardListUpdateAfterFunc(keyName, key) {
                let boardIndex = this.List.findIndex(board => board[keyName] === key)
                if(boardIndex < 0) {
                    return false;
                }
                let params = JSON.parse(JSON.stringify(this.listApiParamSet));
                params.ip = 0;
                params.id = key;
                try {
                    this.$api.$board[this.getFunc](params).then(res => res.Data.Result).then(res => {
                        console.log(res);

                        if(res.Info.type === 1 && res.List.length > 0) {
                            this.List.splice(boardIndex, 1, res.List[0]);
                            this.$forceUpdate();
                        }
                    })

                }catch (e) {
                    console.log(e);
                }
            },
        },
        watch: {

        },
    }
</script>
