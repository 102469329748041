<template>
    <component :is="component" :boardData="boardData" v-if="component"  />
</template>
<script>
    export default {
        name: 'BoardWriteLink',
        props: {
            writeLayout: {

            },
            boardData: {
                default: {}
            },
        },
        data() {
            return {
                component: null,
            }
        },
        computed: {
            loader() {
                if (!this.writeLayout) {
                    return null
                }
                return () => import(`@/template/board/write/${this.writeLayout}`)
            },
        },
        mounted() {
            this.loader()
                .then(() => {
                    this.component = () => this.loader()
                })
                .catch(() => {
                    this.component = () => import('@/template/board/write/BoardWriteInputLayout')
                })
        },
    }
</script>