<template>
    <div  class="content">
        <div class="editor" v-html="termsText"></div>
    </div>
</template>

<script>
    import termsConfig from "@/components/terms/termsConfig";
    import vuxAlert from "@/mixins/vux-alert";
    import util from "@/mixins/util";
    import EventBus from "@/utils/event-bus";
    export default {
        name: "TermsComponent",
        mixins: [termsConfig, vuxAlert],
        components: {
        },
        props: {
            termsType: {}
        },
        data() {
            return{
                termsKey: '',
                termsText: "",
            }
        },
        beforeRouterEnter() {},
        created() {
            this.initConfig();
            this.getTerms();
        },
        mounted() {
        },
        beforeDestroy() {
        },
        destroyed() {},
        computed: {
        },
        methods:{
            initConfig() {
                let termsConfig = this.returnTermsConfig(this.termsType);
                console.log(termsConfig)
                this.termsKey = termsConfig.termsKey;
            },
            getTerms() {
                let params = {
                    c_type: 'service',
                    ip: 1,
                }
                try {
                    this.$api.$config.getConfig(params).then(res => res.Data.Result).then(res => {
                        if(res.Info.type != 1) {
                            return false;
                        }
                        let config = res.List[0];
                        this.termsText = util.htmlDecode(config[this.termsKey]);
                    })

                }catch (e) {
                    console.log(e)
                }
            },
        },
        watch: {
            'termsType' : {
                deep: true,
                handler: function (val, oldVal) {
                    this.initConfig();
                    this.getTerms();
                }
            },
        },
    }
</script>

<style scoped>

</style>